import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './layouts/Layout';
import { APIProvider } from '@vis.gl/react-google-maps';
import { LeftMenuContext, LeftMenuContextProps } from './components/LeftMenu';
import { useAuth } from 'react-oidc-context';
import { OrganizationContext } from './common/contexts/organization';
import EmptyLayout from './layouts/EmptyLayout';
import Landing, { AuthLanding } from './pages/Landing';
import Home from './pages/Home';
import Fields from './pages/Fields';
import User from './pages/User';
import FieldDetails from './pages/FieldDetails';
import Compare from './pages/Compare';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { PositionContext, defaultPositionManager } from './common/contexts/position';
import { graphqlURL, gtmId } from './config';
import { initDB } from './common/db/db';
import i18n from "i18next";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Loading from './common/components/Loading';
import RoleGuard from './common/guards/RoleGuard';
import TagManager from 'react-gtm-module';
import TrackPageView from './common/components/TrackPageView';

function App() {
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? 'AIzaSyD68kpit7GVfUExBXN8EwiKmBH9pk-34lA';

  const [ leftMenuOpen, setLeftMenuOpen ] = React.useState<boolean>(false);
  const leftMenuCtxValue: LeftMenuContextProps = { open: leftMenuOpen, setOpen: setLeftMenuOpen };

  const [ selectedOrganizationID, setSelectedOrganizationID ] = React.useState<string | null>(localStorage.getItem('currentOrganizationId'));
  const organizationCtxValue = { organizationID: selectedOrganizationID, setOrganizationID: setSelectedOrganizationID };

  const [ client, setClient ] = React.useState<ApolloClient<any> | null>(null);

  document.documentElement.lang = i18n.resolvedLanguage ?? 'en';

  initDB('photogrammetry');

  const auth = useAuth();

  const positionManager = defaultPositionManager;

  React.useEffect(() => {
    if (!gtmId) {
      console.warn('GTM_ID is not set');
      return;
    }

    const tagManagerArgs = {
      gtmId,
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  React.useEffect(() => {
    if (!auth.user) {
      return;
    }

    const authLink = setContext((_, { headers }) => {
      // get the authentication token from local storage if it exists
      const token = auth.user?.access_token;
      // return the headers to the context so httpLink can read them
      const h = {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : "",
        }
      }
      if (selectedOrganizationID) {
        h.headers['current-organization'] = selectedOrganizationID;
      }
      return h;
    });
  
    const httpLink = createHttpLink({
      uri: graphqlURL,
    });

    if (!client) {
      setClient(new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache(),
      }));
    } else {
      client.setLink(authLink.concat(httpLink));
    }
  }, [auth.user?.access_token]);

  // import active drone and organization from url
  React.useEffect(() => {
    if (!auth.user) {
      return;
    }

    const url = new URL(window.location.href);
    const organizationID = url.searchParams.get('organization');

    if (organizationID) {
      setSelectedOrganizationID(organizationID);
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, [auth.user]);

  React.useEffect(() => {
    if (!auth.user) {
      return;
    }

    const currentOrganizationId = localStorage.getItem('currentOrganizationId');

    if (selectedOrganizationID) {
      localStorage.setItem('currentOrganizationId', selectedOrganizationID);
    }

    if (currentOrganizationId !== selectedOrganizationID) {
      window.location.reload();
    }

    if (auth.user.expired) {
      return;
    }
  }, [selectedOrganizationID, auth.user]);

  if (auth.isLoading) {
    return (
      <Loading open />
    );
  }

  if (auth.error) {
    window.location.href = '/';
    return <div>Oops... {auth.error.message}</div>;
  }

  if(!client || !auth.isAuthenticated) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<EmptyLayout />}>
            <Route index element={<Landing />} />
            <Route path="app" element={<AuthLanding />} />
            <Route path="app/*" element={<AuthLanding />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }
  if (!auth.isAuthenticated) {
    return (
      <Loading open />
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ApolloProvider client={client}>
        <APIProvider apiKey={apiKey} libraries={['geometry']}>
          <PositionContext.Provider value={positionManager}>
            <OrganizationContext.Provider value={organizationCtxValue}>
              <LeftMenuContext.Provider value={leftMenuCtxValue}>
                <BrowserRouter>
                  <TrackPageView />
                  <Routes>
                    <Route path="/" element={<EmptyLayout />}>
                      <Route index element={<Landing />} />
                      <Route path="app" element={<RoleGuard requiredRoles={["visualization_base"]} featureName="photogrammetry_main_pages"><Layout /></RoleGuard>}>
                        <Route index element={<Home />} />
                        <Route path="home" element={<Home />} />
                        <Route path="inspections" element={<Fields />} />
                        <Route path="user" element={<User />} />
                      </Route>
                      <Route path="app/inspections/:fieldId" element={<RoleGuard requiredRoles={["visualization_photogrammetry", "visualization_base"]} featureName="photogrammetry_field_details"><FieldDetails /></RoleGuard>} />
                      <Route path="app/inspections/:fieldId/compare/:compareAnalysisID/:compareIndex" element={<RoleGuard requiredRoles={["visualization_photogrammetry", "visualization_base"]} featureName="photogrammetry_field_details"><Compare /></RoleGuard>} />
                    </Route>
                  </Routes>
                </BrowserRouter>
              </LeftMenuContext.Provider>
            </OrganizationContext.Provider>
          </PositionContext.Provider>
        </APIProvider>
      </ApolloProvider>
    </LocalizationProvider>
  );
}

export default App;
